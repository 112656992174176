import React from "react"

import { navigate } from "gatsby"

import { ApplicationContext, AuthenticationContext } from "../../context"
import { ORDER_CONFIRM } from "../../utils/constants"
import { toastMessage, TYPE_SUCCESS } from "../UI/Toast"
import AppNavbar from "./AppNavbar"
import HomeInfo from "./HomeInfo"
import Locker from "./Locker"

import "./DropOffComponent.scss"

const DropOffComponent = () => {
  const {
    selectedOrders,
    confirmOrder,
    selectedTypes,
    setSelectedOrders,
    abandonOrders,
  } = React.useContext(ApplicationContext)
  const { isLoadingRequest, setIsLoadingRequest } = React.useContext(
    AuthenticationContext
  )
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [isWashing, setIsWashing] = React.useState(false)
  const [isDryCleaning, setIsDryCleaning] = React.useState(false)
  const [confirmedOrders, setConfirmedOrders] = React.useState([])
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setSelectedOrders(selectedTypes)

    checkNavigate()
    setIsMounted(true)
  }, [confirmedOrders])

  const handleConfirm = async (id) => {
    setIsLoadingRequest(true)
    const params = {
      order_ids: [id],
    }
    const confirmResponse = await confirmOrder(params)
    if (confirmResponse.status === 200) {
      toastMessage(ORDER_CONFIRM, TYPE_SUCCESS)
      setConfirmedOrders((prev) => [...prev, id])
    }
    setIsLoadingRequest(false)
  }

  const checkNavigate = () => {
    if (
      selectedOrders.length === confirmedOrders.length &&
      selectedOrders.length
    ) {
      navigate("/drop-off-confirm")
    }
  }

  const lockers = selectedOrders.map((order, index) => (
    <Locker
      key={index}
      typeCategory={order.order_type}
      id={order.id}
      orderId={order.order_id}
      lockerId={order.customer_drop_off_locker_id}
      isConfirmed={confirmedOrders.includes(order.id)}
      isLoadingRequest={isLoadingRequest}
      handleConfirm={handleConfirm}
    />
  ))

  if (!isMounted) {
    return <div></div>
  }

  const handleBack = () => {
    setIsLoadingRequest(true)
    const tempOrders = selectedOrders.filter(
      (order) => !confirmedOrders.includes(order.id) && order.id
    )
    abandonOrders(tempOrders)
  }

  const ComponentDescription = ({
    isDryCleaning,
    isWashing,
    selectedOrders,
  }) => {
    selectedOrders
      .map((order) => order.order_type)
      .forEach((type) => {
        if (type === "washing") {
          setIsWashing(true)
        } else {
          setIsDryCleaning(true)
        }
      })
    if (isWashing && isDryCleaning)
      return (
        <p>
          If you have chosen both washing and dry-cleaning please separate items
          into correct piles, locate locker numbers specified above and
          carefully place items into the corresponding lockers. Once your items
          have been stored firmly close the locker door to ensure your threads’
          security.
        </p>
      )
    else
      return (
        <p>
          Please locate the locker number specified above and carefully place
          items inside. Once items have been stored, firmly close the locker
          door to ensure your threads’ security.
        </p>
      )
  }

  return (
    <div className="drop-off-container">
      <div className="drop-off-header">
        <AppNavbar setIsInfoOpen={setIsInfoOpen} isInfoOpen={isInfoOpen} />
      </div>
      <div className="drop-off-body">
        <div className="drop-off-body__grid">{lockers}</div>

        <div className="drop-off-body__description">
          {selectedOrders.length && (
            <ComponentDescription
              isWashing={isWashing}
              isDryCleaning={isDryCleaning}
              selectedOrders={selectedOrders}
            />
          )}
        </div>
      </div>
      <button
        type="button"
        disabled={isLoadingRequest}
        className="homescreen-component__body__logout"
        onClick={() => handleBack()}
        style={{ marginBottom: 50 }}
      >
        Back
      </button>
      <div className="homescreen-component__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isInfoOpen && <HomeInfo setIsInfoOpen={setIsInfoOpen} />}
    </div>
  )
}

export default DropOffComponent
