import React, { useContext, useState } from "react"

import "./AddNotes.scss"
import PropTypes from "prop-types"

import { AuthenticationContext } from "../../context/authentication/AuthenticationContext"
import mainAxios from "../../utils/axiosConfig"

const AddNotes = ({ setIsNotesFieldOpen, setIsNotesAdded, id }) => {
  const [inputValue, setInputValue] = useState("")
  const { checkIsAuthenticated } = useContext(AuthenticationContext)
  const addOrderNote = () => {
    if (checkIsAuthenticated()) {
      mainAxios
        .put("/orders", { customer_note: inputValue, id })
        .then((res) => {
          setIsNotesFieldOpen(false)
          setIsNotesAdded(true)
        })
    }
  }

  return (
    <div className="add-notes">
      <div className="add-notes__container">
        <div className="add-notes__top">+ Add special instructions</div>
        <textarea
          type="text"
          className="add-notes__input"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Is there anything you'd like Washroom staff to know about your garments? (eg. special care instructions, delicates, etc) Add it here."
        />
        <div className="add-notes__buttons">
          <button
            className="add-notes__buttons__cancel-btn"
            onClick={() => setIsNotesFieldOpen(false)}
          >
            Cancel & Back
          </button>
          <button
            className="add-notes__buttons__add-btn"
            onClick={addOrderNote}
          >
            Add notes to order
          </button>
        </div>
      </div>
    </div>
  )
}

AddNotes.propTypes = {
  id: PropTypes.any.isRequired,
  setIsNotesAdded: PropTypes.func.isRequired,
  setIsNotesFieldOpen: PropTypes.func.isRequired,
}

export default AddNotes
