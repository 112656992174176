import React, { useState, useEffect, useContext } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import dropOffDark from "../../assets/images/Icons/dropoff-dark.svg"
import AddNotes from "./AddNotes"

import "./Locker.scss"
import { AuthenticationContext } from "../../context/authentication/AuthenticationContext"
import mainAxios from "../../utils/axiosConfig"

const Locker = ({
  typeCategory,
  id,
  lockerId,
  orderId,
  isConfirmed,
  handleConfirm,
  isLoadingRequest,
}) => {
  const [isNotesFieldOpen, setIsNotesFieldOpen] = useState(false)
  const [isNotesAdded, setIsNotesAdded] = useState(false)
  const [coupon, setCoupon] = useState("")
  const { checkIsAuthenticated } = useContext(AuthenticationContext)
  const classNameBodyButton = classNames({
    operation__body__button: true,
    "operation__body__button--confirmed": isConfirmed,
  })

  useEffect(() => {
    if (isNotesFieldOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }, [isNotesFieldOpen])


  const addCoupon = () => {
    if (checkIsAuthenticated()) {
      mainAxios
        .put("/orders", { coupon: coupon, id })
        .then((res) => {
          console.log(res)
        })
    }
  }

  const confirmButton = () => {
    if (coupon) addCoupon()
    handleConfirm(id)
  }

  return (
    <>
      <div className="operation">
        <div className="operation__icon">
          <img src={dropOffDark}></img>
        </div>
        <div className="operation__heading">
          <p>{typeCategory ? typeCategory.replace(/_/g, " ") : ""} Drop Off</p>
        </div>
        <button
          className={`operation__add-instruction-btn ${isNotesAdded && "operation__add-instruction-btn--added"
            } `}
          onClick={() => setIsNotesFieldOpen(true)}
        >
          {isNotesAdded
            ? "Special instructions added"
            : "+ Add special instructions"}
        </button>
        <div className="operation__body">
          {isConfirmed && <div className="operation-disabled"></div>}
          <div className="operation__body__text">
            {typeCategory ? (
              <p>
                Add {typeCategory ? typeCategory.replace(/_/g, " ") : ""} to
                locker
              </p>
            ) : (
              <p>No Locker Available </p>
            )}
          </div>
          <div className="operation__body__days">
            {lockerId ? (
              <p>{lockerId}</p>
            ) : !isConfirmed && lockerId ? (
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : null}
          </div>
          <div className="operation__body__coupon">
            <input
              type="text" name="coupon" placeholder="Enter promo code"
              onChange={e => setCoupon(e.target.value)}
            />
          </div>
          <div className={classNameBodyButton}>
            {id && (
              <button
                disabled={isLoadingRequest || isConfirmed}
                onClick={confirmButton}
              >
                {isConfirmed ? "Confirmed" : "Confirm"}
              </button>
            )}
          </div>
          <div className="operation__body__order-id">
            <p>{orderId}</p>
          </div>
        </div>
      </div>
      {isNotesFieldOpen && !isNotesAdded && (
        <AddNotes
          id={id}
          setIsNotesFieldOpen={setIsNotesFieldOpen}
          setIsNotesAdded={setIsNotesAdded}
        />
      )}
    </>
  )
}

Locker.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  isLoadingRequest: PropTypes.bool.isRequired,
  lockerId: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  typeCategory: PropTypes.string.isRequired,
}

export default Locker
